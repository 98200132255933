const { default: styled } = require("styled-components");
const { primaryColors } = require("../styles/colours");

const StyledSubHeading = styled.h2`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 36px;
  color: ${primaryColors.violet};
  text-align: center;
  @media (max-width: 600px) {
    font-size: 30px;
  }
`;
const StyledPageHeading = styled.h1`
  font-size: 40px;
  color: ${primaryColors.violet};
  @media (max-width: 600px) {
    font-size: 30px;
  }
`;
const StyledPageSubHeading = styled.h2`
  font-size: 24px;
  color: ${primaryColors.violet};
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const Wrapper = styled.div`
  max-width: 1150px;
  margin: auto;

  & strong {
    color: ${primaryColors.violet};
  }

  & h2 {
    color: ${primaryColors.violet};
    margin: 0;
    padding: 0;
  }
  @media (max-width: 600px) {
    padding: 0 24px;
    img {
      margin: 8px 0 16px 0;
    }
  }
`;

const SocialMediaLinks = styled.a`
  color: ${primaryColors.paleGreen};
  font-size: 22px;
  text-decoration: none;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const StyledPageCopy = styled.p`
  color: ${({ color }) => color || primaryColors.paleGreen};
  font-size: ${({ smallPrint }) => (smallPrint ? "14px" : "18px")};
  @media (max-size: 600px) {
    font-size: ${({ smallPrint }) => (smallPrint ? "12px" : "16px")};
  }
`;

const StyledBlockQuote = styled.blockquote`
  text-align: left;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  & ::before {
    content: "\\201C";
    font-size: 60px;
    color: ${primaryColors.paleGreen};
    font-family: Georgia, serif;
    position: absolute;
    top: 0;
    left: -24px;
  }
  & cite {
    color: ${primaryColors.paleGreen};
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }
  & .author {
    color: #999999;
    ::before {
      content: "\\2014 \\2009";
    }
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ErrorMessage = styled.p`
  font-size: 14px;
  color: red;
`;

const DirectorsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const DirectorImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  img {
    margin-top: 16px;
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 600px) {
    height: 200px;
    margin-bottom: 32px;
  }
`

export {
  StyledSubHeading,
  StyledPageHeading,
  StyledPageSubHeading,
  Wrapper,
  SocialMediaLinks,
  StyledPageCopy,
  StyledBlockQuote,
  ErrorMessage,
  DirectorsLayout,
  DirectorImageWrapper
};
