import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo, useState } from "react";
import { FullScreenImage, HeadlineCopy, HeadlineWrapper } from "../pages";

export const HeroImage = ({ page, pageTitle }) => {
  const data = useStaticQuery(graphql`
    query DirectorsHeroImageQuery {
      allGoogleHeroimagesSheet {
        edges {
          node {
            page
            title
            imageurl
          }
        }
      }
    }
  `);

  const object = useMemo(
    () =>
      data?.allGoogleHeroimagesSheet?.edges?.find(
        ({ node }) => node.page === page
      ),
    [data, page]
  );

  const [pageData] = useState(object?.node);

  return (
    <FullScreenImage imageUrl={pageData?.imageurl}>
      <HeadlineCopy>
        <HeadlineWrapper>
          <h1>{pageTitle || pageData?.title}</h1>
        </HeadlineWrapper>
      </HeadlineCopy>
    </FullScreenImage>
  );
};
