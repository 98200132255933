import React from "react";
import { ProgramGrid } from "../components/ProgramGrid";
import { ProgrammesProvider } from "../hooks/useProgrammes";

export const Programs = () => {
  return (
    <ProgrammesProvider>
      <ProgramGrid />
    </ProgrammesProvider>
  );
};
