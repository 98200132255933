import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import { Container, Spacer } from "ui-start";
import { primaryColors } from "../styles/colours";
import useMedia from "use-media";
import { Programs } from "../containers/programs";
import { Partners } from "../components/partners";
import { HeroImage } from "../components/heroImage";

export const FullScreenImage = styled.div`
  width: 100vw;
  height: 400px;
  overflow: hidden;
  background-image: ${({ imageUrl }) => imageUrl && `url(${imageUrl})`};
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  align-self: center;
  justify-content: center;
  @media (max-width: 600px) {
    height: 190px;
  }
`;

export const HeadlineWrapper = styled.div`
  color: white;
  display: flex;
  background-color: ${primaryColors.paleGreen};
  width: fit-content;
  padding: 16px;
  font-family: alternate-gothic-atf, sans-serif;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const HeadlineCopy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 600px) {
    h1 {
      font-size: 18px;
    }
  }
`;

export const HomePageContentWrapper = styled.div`
  max-width: 1200px;
  padding: 0 24px;
  p {
    line-height: 32px;
    font-size: 24px;
  }
  @media (max-width: 600px) {
    p {
      font-size: 16px;
    }
  }
`;

const IndexPage = () => {
  const isMobile = useMedia({ maxWidth: 600 });
  return (
    <Layout>
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            googleHomeSheet {
              heroimage
              title
              introtext
            }
          }
        `}
        render={({ googleHomeSheet: { title, heroimage, introtext } }) => (
          <>
            <header>
              <HeroImage page={'home'} />
            </header>
            <Container center>
              <Spacer height={isMobile ? 16 : 48} />
              <HomePageContentWrapper>
                <div dangerouslySetInnerHTML={{ __html: introtext }} />
              </HomePageContentWrapper>
              <Spacer height={32} />
              <Programs />
              <Spacer height={32} />
              <Partners />
            </Container>
          </>
        )}
      />
    </Layout>
  );
};

export default IndexPage;
