import constate from "constate";
import { graphql, useStaticQuery } from "gatsby";
import { useEffect, useState } from "react";

const useProgrammes = () => {
  const [programmesData, setProgrammesData] = useState([]);

  const data = useStaticQuery(graphql`
    query programViewQuery {
      allGoogleSheet {
        edges {
          node {
            programs {
              copy
              title
              icon
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setProgrammesData(data?.allGoogleSheet?.edges?.[0]?.node?.programs);
  }, [data]);

  return {
    programmesData,
    setProgrammesData,
  };
};

const [ProgrammesProvider, useProgrammesContext] = constate(useProgrammes);
export { ProgrammesProvider, useProgrammesContext };
