import React from "react";
import styled from "styled-components";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  ${({ rows }) => rows && `grid-template-rows: repeat(${rows}, 1fr);`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px};`}
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px};`}
  @media (max-width: 996px) {
    ${({ columnsTablet }) =>
      columnsTablet && `grid-template-columns: repeat(${columnsTablet}, 1fr);`}
  }
  @media (max-width: 600px) {
    ${({ columnsMobile }) =>
      columnsMobile && `grid-template-columns: repeat(${columnsMobile}, 1fr);`}
  }
`;

export const Grid = ({
  dataTestId,
  children,
  columns,
  columnsMobile,
  columnsTablet,
  rows,
  rowGap,
  columnGap,
}) => (
  <StyledGrid
    data-testid={dataTestId}
    columns={columns}
    columnsMobile={columnsMobile}
    columnsTablet={columnsTablet}
    rows={rows}
    rowGap={rowGap}
    columnGap={columnGap}
  >
    {children}
  </StyledGrid>
);
