import React from "react";
import styled from "styled-components";
import { Container } from "ui-start";
import { StyledSubHeading } from "../components/styles";
import { Grid } from "../components/grid";
import { ProgramCard } from "./programCard";
import { useProgrammesContext } from "../hooks/useProgrammes";

const Wrapper = styled.div`
  max-width: 1150px;
  margin: auto;
  @media (max-width: 600px) {
    padding: 0 24px;
    text-align: center;
    img {
      margin: 8px 0 16px 0;
    }
    p {
      font-size: 16px;
      span {
        font-size: 20px;
      }
    }
  }
`;

export const ProgramGrid = () => {
  const { programmesData } = useProgrammesContext();
  return (
    <Wrapper>
      <Container>
        <StyledSubHeading>ELSA Programmes</StyledSubHeading>
        <Grid columns={3} columnsMobile={1} columnsTablet={2} rowGap={40}>
          {programmesData?.map(({ title, icon, copy }) => (
            <ProgramCard title={title} icon={icon} copy={copy} />
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};
