import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Container, Spacer } from "ui-start";
import { Grid } from "./grid";
import { StyledSubHeading, Wrapper } from "./styles";

export const Partners = () => {
  const data = useStaticQuery(graphql`
    query partnerQuery {
      allGooglePartnersSheet {
        edges {
          node {
            url
            partner
          }
        }
      }
    }
  `);

  const programData = data?.allGooglePartnersSheet?.edges;
  const sortedData = programData.sort((a, b) => {
    const nameA = a.node.partner.toUpperCase();
    const nameB = b.node.partner.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return (
    <Wrapper>
      <Container>
        <StyledSubHeading>ELSA Partners</StyledSubHeading>
        <Spacer height={16} />
        <Grid columns={12} columnsMobile={4} columnsTablet={8} columnGap={32}>
          {sortedData?.map(({ node: { url } }) => (
            <Container padding="0 16px" center key={url}>
              <img
                src={url}
                width={"100%"}
                alt="Bespoke (modular) programmes"
              />
            </Container>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};
