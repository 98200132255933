import React from "react";
import { Container, Copy } from "ui-start";
import { primaryColors } from "../styles/colours";

export const ProgramCard = ({ icon, title, copy }) => (
  <Container padding="0 16px" centerVertical>
    <img src={icon} height={40} alt="Bespoke (modular) programmes" />
    <Copy fontSize={30} color={primaryColors.violet}>
      <span>{title}</span>
    </Copy>
    <Copy fontSize={20} dangerouslySetInnerHTML={{ __html: copy }}>
      <span dangerouslySetInnerHTML={{ __html: copy }} />
    </Copy>
  </Container>
);
